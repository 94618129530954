require([
  'lib/cookies',
  'lib/cookie-consent'
],function(Cookies){
  var consent = Cookies.get('si-cookie-settings');
  var hideBanner = document.getElementsByTagName('html')[0].dataset.hideCookieBanner;

  var optimalItems = [
    {
      title: "optimalGaTitle",
      description: "optimalGaDescription",
      originText: "optimalGaOriginText",
      originUrl: "https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage",
      expires: "optimalGaExpires",
      type: "optimalGaType"
    }
  ];

  var basisItems = [
    {
      title: "basisGaTitle",
      description: "basisGaDescription",
      originText: "basisGaOriginText",
      originUrl: "https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage",
      expires: "basisGaExpires",
      type: "basisGaType"
    }
  ];

  var cookieConsentI18n = {
    "de": {
      "title": "Cookie-Einstellungen",
      "description": "Die Websites von Company.info machen von Cookies und/oder vergleichbaren Techniken Gebrauch, sowohl von uns selbst als auch von Dritten, um unsere Websites zu analysieren und optimal unseren Service zu verbessern und um auf anderen Websites relevante Anzeigen präsentieren zu können. Sie können selbst entscheiden, mit welchen Cookie-Einstellungen Sie unsere Website besuchen möchten.",
      "showDetails": "Cookie-Details anschauen",
      "optimal": "Optimal",
      "optimalDescription": "Mit diesen Einstellungen nutzen Sie unsere Website optimal; es stehen Ihnen also alle Funktionen und relevanten Informationen zur Verfügung. Es werden Cookies zur Personalisierung installiert.",
      "optimalChoice": "Ich wähle die einstellung “optimal”",
      "basis": "Basis",
      "basisDescription": "Mit diesen Einstellungen können Sie alle Basisfunktionen nutzen, die für eine ordnungsgemäße Funktion der Website benötigt werden. Es werden anonyme Analyse-Cookies gesetzt.",
      "basisChoice": "Ich wähle die einstellung “basis”",
      "detailsTitle": "Von uns verwendete Cookies",
      "privacyStatement": "Datenschutz- und Cookie-Erklärung",
      "privacyStatementDescription": "Nähere Informationen über die von uns verwendeten Cookies finden Sie in unserer ",
      "basisDetailsTitle": "Anonyme Analyse-Cookies (Basis)",
      "optimalDetailsTitle": "Personalisierung und Anzeigen (Optimal)",
      "origin": "Herkunft",
      "expiration": "Verfallszeit",
      "type": "Typ",
      "items": {
        "basisGaTitle": 'Google Analytics',
        "basisGaDescription": 'Zur (anonymen) Messung und Analyse der Website-Aufrufe.',
        "basisGaOriginText": 'Google Analytics',
        "basisGaExpires": '2 Jahre',
        "basisGaType": 'HTTP',
        "optimalGaTitle": "Google Analytics",
        "optimalGaDescription": "Zur (personalisierten) Messung und Analyse der Website-Aufrufe.",
        "optimalGaOriginText": "Google Analytics",
        "optimalGaExpires": "2 Jahre",
        "optimalGaType": "HTTP",
      }
    }
  };

  var privacyStatementUrl = "https://companyinfo.de/datenschutz-und-cookie-erklarung";

  if (!consent) {
    if (hideBanner) { // If no consent, don't show banner or add any scripts
      return;
    }
    var consentBanner = new cookieConsent({
      target: document.body,
      props: {
        activeLocale: 'de', // Currently we only have german translations, pass companyInfo.lang if more languages are added
        i18n: cookieConsentI18n,
        optimalItems: optimalItems,
        basisItems: basisItems,
        privacyStatementUrl: privacyStatementUrl,
        collapsibleDetails: false
      }
    });

    consentBanner.$on('select', function(e) {
      var consentType = e.detail;
      Cookies.set('si-cookie-settings', consentType, { // cookie name is same one used in commercial website
        expires: 365
      });

      if (consentType === 'full') {
        installAllScripts();
      } else {
        //window.dataLayer = []; // Do not send any CI data
        installGoogleTagManager();
      }

      consentBanner.$destroy(); // Remove from dom
    });
  } else {
    if (consent === 'full') {
      if (hideBanner) { // For pages where we don't show the banner, we only add GTM
        installGoogleTagManager();
      } else {
        installAllScripts();
      }
    } else {
      //window.dataLayer = []; // Do not send any CI data
      installGoogleTagManager();
    }
  }

  function installAllScripts() {
    // Add more init scripts here if needed
    installGoogleTagManager();
  }

  function installGoogleTagManager() {
    window['dataLayer'].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
    var f=document.getElementsByTagName('script')[0],
    j=document.createElement('script');
    j.async=true;
    j.src= 'https://www.googletagmanager.com/gtm.js?id=GTM-TNVWMV6';
    f.parentNode.insertBefore(j,f);
  }

});

define("cookie-consent", function(){});

